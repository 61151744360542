import styled, { css } from "styled-components";

export interface StyledTextProps {
  $uppercase?: boolean;
  size?: "l" | "m" | "s" | "xs" | "label-abc" | "label-val";
}

export const StyledText = styled.p<StyledTextProps>`
  display: block;
  font-family: ${({ theme }) => theme.fonts.abcWalterNeue};
  letter-spacing: ${({ theme }) => theme.letterSpacings.default};
  margin: 0;
  overflow: hidden;
  position: relative;

  ${({ size, theme }) =>
    size === "l" &&
    css`
      font-size: ${theme.fontSizes[18]};
      font-weight: ${theme.fontWeights.normal};
      line-height: ${theme.lineHeights[24]};
    `}

  ${({ size, theme }) =>
    size === "m" &&
    css`
      font-size: ${theme.fontSizes[16]};
      font-weight: ${theme.fontWeights.normal};
      line-height: ${theme.lineHeights[24]};
    `}

  ${({ size, theme }) =>
    size === "s" &&
    css`
      font-size: ${theme.fontSizes[12]};
      font-weight: ${theme.fontWeights.thin};
      line-height: ${theme.lineHeights[16]};

      ${theme.breakpoints.up("desktop")} {
        font-weight: ${theme.fontWeights.normal};
      }
    `}

  ${({ size, theme }) =>
    size === "xs" &&
    css`
      font-size: ${theme.fontSizes[10]};
      font-weight: ${theme.fontWeights.thin};
      line-height: ${theme.lineHeights[16]};
    `}

  ${({ size, theme }) =>
    size === "label-abc" &&
    css`
      font-size: ${theme.fontSizes[12]};
      font-weight: ${theme.fontWeights.normal};
      line-height: ${theme.lineHeights[16]};
    `}

  ${({ size, theme }) =>
    size === "label-val" &&
    css`
      font-family: ${theme.fonts.valentineLL};
      font-size: ${theme.fontSizes[10]};
      font-weight: ${theme.fontWeights.normal};
      line-height: ${theme.lineHeights[16]};
    `}

  ${({ $uppercase }) =>
    $uppercase &&
    css`
      text-transform: uppercase;
    `}
`;

export const AnimatedText = styled.span`
  left: 0;
  position: absolute;
  top: 0;
`;

export const OriginalText = styled.span`
  visibility: hidden;
`;
