import {
  ComponentProps,
  ElementType,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { AnimatedText, OriginalText, StyledText } from "./Text.styles";
import { ScrambleOptions, useScramble } from "~/hooks/useScramble";
import { useIntersection } from "react-use";

export interface TextProps
  extends Omit<ComponentProps<typeof StyledText>, "$uppercase"> {
  as?: ElementType;
  children: ReactNode;
  hasMotion?: boolean;
  scrambleOptions?: Omit<ScrambleOptions, "paused">;
  uppercase?: boolean;
}

export interface AnimatedTextProps
  extends Omit<TextProps, "children" | "hasMotion"> {
  children: string;
  hasMotion: true;
}

const getCharacters = () => {
  const characters = [];

  for (let i = 0; i < 26; i += 1) {
    // Characters a-z
    characters.push(String.fromCharCode(97 + i));
  }

  characters.push("/", ".", ":", "-", "_", "{", "}", "\\", "[", "]", ">");

  return characters;
};

const Text = forwardRef<HTMLElement, AnimatedTextProps | TextProps>(
  (
    {
      children,
      hasMotion = false,
      scrambleOptions = {},
      uppercase = true,
      ...props
    },
    ref
  ) => {
    const [isPaused, setIsPaused] = useState(true);
    const intersectionRef = useRef<HTMLElement>(null);

    useImperativeHandle(ref, () => intersectionRef.current as HTMLElement);

    const intersection = useIntersection(intersectionRef, {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    });

    const text = useScramble(typeof children === "string" ? children : "", {
      ...scrambleOptions,
      characters: scrambleOptions.characters || getCharacters(),
      paused: isPaused,
    });

    useEffect(() => {
      if (hasMotion && intersection?.intersectionRatio === 1) {
        setIsPaused(false);
      }
    }, [hasMotion, intersection?.intersectionRatio]);

    return (
      <StyledText {...props} $uppercase={uppercase} ref={intersectionRef}>
        {hasMotion ? (
          <>
            <AnimatedText>{text}</AnimatedText>
            <OriginalText>{children}</OriginalText>
          </>
        ) : (
          children
        )}
      </StyledText>
    );
  }
);

Text.displayName = "Text";

export default Text;
